import React, { Component } from "react";
import YoutubePlayer from 'youtube-player';
import { RedditTVContext } from "../Provider";
import Tools from "../utils/Tools";

export default class VideoPlayer extends Component {
	YTPlayer: any;
	currentVideo: VideoObj = this.context.state.currentVideo;
	currentSub: string = this.context.state.currentSub;
	context: IRedditTVContext;

	componentDidMount() {
		this.YTPlayer = YoutubePlayer('YTPlayer', {
			playerVars: {
				disablekb: 0,
				modestbranding: 1,
				autoplay: 1
			}
		});
		this.context.initYTPlayer(this.YTPlayer);
		this.YTPlayer.on('stateChange', this.YTStateChance);
	}

	componentDidUpdate() {
		if (this.currentVideo !== this.context.state.currentVideo) {
			this.currentVideo = this.context.state.currentVideo;
			this.YTPlayer.loadVideoById({
				videoId: this.currentVideo.id,
				startSeconds: this.currentVideo.timestamp
			}).then(() => this.YTPlayer.playVideo());
		}

		if (this.currentSub !== this.context.state.currentSub) {
			this.currentSub = this.context.state.currentSub;
			this.YTPlayer.pauseVideo();
		}
	}

	render() {
		return (
			<div className="VideoPlayer">
				<div>
					<div id="YTPlayer" className="youtube-iframe"></div>
				</div>
			</div>
		)
	}

	YTStateChance = (e: any) => {
		if (e.data == 0) {
			if (!this.context.state.autoplay) return;
			this.context.setNextVideo();
		}
	}
}
VideoPlayer.contextType = RedditTVContext;