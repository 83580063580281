import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { RedditTVProvider } from './Provider';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
ReactDOM.render(
	<RedditTVProvider>
		<Router>
			<div>
				<Route path="/:subreddit?/:video?" component={App} />
			</div>
		</Router>
	</RedditTVProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
