import React, { Component } from 'react';
import './scss/application.scss';
import Header from './components/Header';
import Viewport from './components/Viewport';
import { RedditTVContext } from './Provider';
import { RouteComponentProps } from 'react-router-dom';

interface RouterProps extends RouteComponentProps<any>, React.Props<any> { }
class App extends Component<{ history: RouterProps, match: any }> {
	context: IRedditTVContext;
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		document.addEventListener('keyup', this.keyUpHandler);
	}

	componentWillUnmount(): void {
		document.removeEventListener('keyup', this.keyUpHandler);
	}

	render() {
		return (
			<div className={`App ${this.context.state.loading ? 'loading' : ''}`}>
				<Header />
				<Viewport history={this.props.history} match={this.props.match} />
			</div>
		);
	}

	keyUpHandler = (e: KeyboardEvent): void => {
		const key = e.which;
		if (!this.context.state.canUseShortcuts) return;
		switch (key) {
			case 67:
				this.openComments();  //C
				break;
			case 70:
				this.fullScreen(document.getElementsByTagName('body')[0]);  //F
				break;
			case 82:
				window.location.href = `${window.location.protocol}//${window.location.host}`; //R
				break;
			case 89:
				this.openOnYoutube();
				break;

			case 39:
				this.context.setNextVideo(); //Right Arrow
				break;

			case 37:
				this.context.setPreviousVideo(); //Left Arrow
				break;
			default:
				return;
		}
	}

	openOnYoutube() {
		let url: string = this.context.state.currentVideo.url;

		this.context.state.YTPlayer.pauseVideo();
		if (url.indexOf('youtu') > -1) {
			this.context.state.YTPlayer.getCurrentTime().then((value: any) => {
				let prefix: string = '';
				let suffix: string = '';
				let time: number = ~~value || 0;
				if (url.indexOf('?') >= 0)
					prefix = "&";
				else
					prefix = "?";

				suffix = "time_continue=" + time

				url = url + prefix + suffix;
				window.open(url);
			});
		} else {
			window.open(url);
		}
	}

	openComments() {
		this.context.state.YTPlayer.pauseVideo();
		window.open(`https://reddit.com${this.context.state.currentVideo.permalink}`);
	}

	fullScreen(elem: any) {
		if (elem.requestFullscreen)
			elem.requestFullscreen();
		else if (elem.msRequestFullscreen)
			elem.msRequestFullscreen();
		else if (elem.mozRequestFullScreen)
			elem.mozRequestFullScreen();
		else if (elem.webkitRequestFullscreen)
			elem.webkitRequestFullscreen();
	}
}
App.contextType = RedditTVContext;

export default App;
