import React, { Component, Fragment } from "react";
import Subreddit from "./Subreddit";
import Shortcuts from "./Shortcuts";
import { RedditTVContext } from "../Provider";

export default class Dropdown extends Component {
	context: IRedditTVContext;

	render() {
		return (
			<Fragment>
				<div
					className={`toggleOptions ${this.context.state.menuOpened ? 'toggleOptions--open' : ''}`}
					onClick={this.toggleOptions}>
					<div></div>
				</div>
				<div className="options">
					<div className="cols">
						<Shortcuts />
						<section>
							<small className="notes">Options</small>
							<label>
								Autoplay
								<input type="checkbox" onChange={this.context.toggleAutoplay} checked={this.context.state.autoplay} />

							</label>
						</section>
					</div>
					<div className="cols">
						<Subreddit />
					</div>
				</div>
			</Fragment>
		)
	}

	toggleOptions = () => {
		this.context.switchMenuState(!this.context.state.menuOpened);
	}
}
Dropdown.contextType = RedditTVContext;