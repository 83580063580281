import React, { Component, useContext } from "react";
import { RedditTVContext } from "../Provider";

export default class Navigation extends Component {
	context: IRedditTVContext;
	render() {
		return (
			<ul className="Navigation links">
				<li><a onClick={this.openOnYoutube}>View on {this.context.state.currentVideo.type}</a></li>
				<li><a href={`https://reddit.com${this.context.state.currentVideo.permalink}`} target="_blank">Comment Thread</a></li>
			</ul>
		)
	}

	openOnYoutube = () => {
		let url: string = this.context.state.currentVideo.url;
		this.context.state.YTPlayer.pauseVideo();
		if (url.indexOf('youtu') > -1) {
			this.context.state.YTPlayer.getCurrentTime().then((value: any) => {
				let prefix: string = '';
				let suffix: string = '';
				let time: number = ~~value || 0;
				if (url.indexOf('?') >= 0)
					prefix = "&";
				else
					prefix = "?";

				suffix = "time_continue=" + time

				url = url + prefix + suffix;
				window.open(url);
			});
		} else {
			window.open(url);
		}
	}

}
Navigation.contextType = RedditTVContext;