interface VideoInfoMetaData {
	id: string;
	type: 'youtube' | 'vimeo';
}

export const getVideoInfo = (str: string) => {
	let metadata: VideoInfoMetaData;

	str = str.replace('-nocookie', '');

	if (/youtube|youtu\.be/.test(str)) {
		metadata = {
			id: youtube(str),
			type: 'youtube'
		};
	} else if (/vimeo/.test(str)) {
		metadata = {
			id: vimeo(str),
			type: 'vimeo'
		};
	}
	return metadata;
}

/**
 * Get the vimeo id.
 * @param {string} str - the url from which you want to extract the id
 * @returns {string|undefined}
 */
function vimeo(str: string): string {
	if (str.indexOf('#') > -1) {
		str = str.split('#')[0];
	}
	if (str.indexOf('?') > -1) {
		str = str.split('?')[0];
	}

	let id: string;
	if (/https?:\/\/vimeo\.com\/[0-9]+$|https?:\/\/player\.vimeo\.com\/video\/[0-9]+$/igm.test(str)) {
		let arr: string[] = str.split('/');
		if (arr && arr.length) {
			id = arr.pop();
		}
	}
	return id;
}

/**
 * Get the Youtube Video id.
 * @param {string} str - the url from which you want to extract the id
 * @returns {string|undefined}
 */
function youtube(str: string): string {
	// shortcode
	let shortcode: RegExp = /youtube:\/\/|https?:\/\/youtu\.be\//g;

	if (shortcode.test(str)) {
		let shortcodeid: string = str.split(shortcode)[1];
		return stripParameters(shortcodeid);
	}

	// /v/ or /vi/
	let inlinev: RegExp = /\/v\/|\/vi\//g;

	if (inlinev.test(str)) {
		let inlineid: string = str.split(inlinev)[1];
		return stripParameters(inlineid);
	}

	// v= or vi=
	let parameterv: RegExp = /v=|vi=/g;

	if (parameterv.test(str)) {
		let arr: string[] = str.split(parameterv);
		return arr[1].split('&')[0];
	}

	// embed
	let embedreg: RegExp = /\/embed\//g;

	if (embedreg.test(str)) {
		let embedid: string = str.split(embedreg)[1];
		return stripParameters(embedid);
	}

	// user
	let userreg: RegExp = /\/user\//g;

	if (userreg.test(str)) {
		let elements: string[] = str.split('/');
		return stripParameters(elements.pop());
	}

	// attribution_link
	let attrreg: RegExp = /\/attribution_link\?.*v%3D([^%&]*)(%26|&|$)/;

	if (attrreg.test(str)) {
		return str.match(attrreg)[1];
	}
}


function stripParameters(str: string): string {
	if (str.indexOf('?') > -1) {
		return str.split('?')[0];
	}
	return str;
}