import React, { Component, Fragment } from "react";
import VideoPlayer from "./VideoPlayer";
import VideoList from "./VideoList";

import { RouteComponentProps } from 'react-router-dom';
interface RouterProps extends RouteComponentProps<any>, React.Props<any> { }

export default class Viewport extends Component<{ history: RouterProps, match: any }> {
	render() {
		return (
			<Fragment>
				<VideoPlayer />
				<VideoList history={this.props.history} match={this.props.match} />
			</Fragment>
		)
	}
}