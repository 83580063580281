import React, { Component } from "react";

interface ShortcutsObj {
	key: string;
	label: string;
}

export default class Shortcuts extends Component {
	shortcuts: ShortcutsObj[] = [
		{
			key: '←',
			label: 'Previous video'
		},
		{
			key: '→',
			label: 'Next video'
		},
		{
			key: 'r',
			label: 'Fetch new videos'
		},
		{
			key: 'f',
			label: 'Fullscreen'
		},
		{
			key: 'c',
			label: 'Open comments (pause the video'
		},
		{
			key: 'y',
			label: 'Open on youtube'
		}
	];
	render() {
		return (
			<section className="Shortcuts">
				<small className="notes">
					Keyboard shortcuts
					<ul className="shortcuts">
						{this.shortcuts.map((shortcut: ShortcutsObj, i: number) => (
							<li key={`shortcut-${i}`}><span className="key">{shortcut.key}</span> {shortcut.label}</li>
						))}
					</ul>
				</small>
			</section>
		)
	}
}