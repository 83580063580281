import React, { Component, FormEvent, ChangeEvent } from "react";
import { RedditTVContext } from "../Provider";
import { Link } from "react-router-dom";


interface SubredditState {
	currentSubreddit: string;
	customSubreddits: string[];
	customSubInput: string;
}

interface SubredditProps {
	defaultSubreddits: string[];
}
export default class Subreddit extends Component<SubredditProps, SubredditState> {
	context: IRedditTVContext;

	static defaultProps = {
		defaultSubreddits: ['videos', 'youtubehaiku', 'playitagainsam', 'artisanvideos']
	}

	constructor(props: SubredditProps) {
		super(props);
		this.state = {
			customSubInput: '',
			currentSubreddit: 'videos',
			customSubreddits: JSON.parse(localStorage.getItem('customSubs')) || []
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	handleCustomInput = (e: ChangeEvent<HTMLInputElement>): void => {
		this.setState({ customSubInput: e.currentTarget.value });
	}

	render() {
		const { customSubreddits, customSubInput } = this.state;
		const { currentSub } = this.context.state;
		const { defaultSubreddits } = this.props;

		return (
			<div className="Subreddit">
				<h3>Xposed's favorite subreddits</h3>
				<ul>
					{defaultSubreddits.map((s: string, i: number) => (
						<li key={`default-${i}`}>
							<Link to={`/${s}/`} className={s === currentSub ? 'active' : ''}>{s}</Link>
							{/* <Link to={`/${s}/`} onClick={(e) => { e.preventDefault(); this.handleSubredditChange(s) }} className={s === currentSub ? 'active' : ''}>{s}</Link> */}
						</li>
					))}
				</ul>

				<h3>Your favorite subreddit</h3>
				<ul id="custom">
					{customSubreddits.map((s: string, i: number) => (
						<li key={`custom-${i}`}>
							<a href={`/${s}/`} className={s === currentSub ? 'active' : ''}>{s}</a>
							<a className="delete" onClick={() => this.handleDelete(s)}>×</a>
						</li>
					))}
				</ul>

				<form onSubmit={this.handleSubmit}>
					<input type="text" name="" onFocus={this.focusHandler} onBlur={this.blurHandler} onChange={this.handleCustomInput} value={customSubInput} placeholder="Add your own" />
				</form>
			</div>
		)
	}

	focusHandler = () => {
		this.context.canUseShortcuts(false);
	}

	blurHandler = () => {
		this.context.canUseShortcuts(true);
	}

	handleSubmit(event: FormEvent) {
		event.preventDefault();
		const { customSubInput } = this.state;
		if (!customSubInput) return;
		if (this.state.customSubreddits.indexOf(customSubInput) > -1) return;
		const customSubreddits: string[] = [...this.state.customSubreddits, customSubInput];
		this.setState({ customSubreddits, customSubInput: '' })
		localStorage.setItem('customSubs', JSON.stringify(customSubreddits));
	}

	handleDelete(sub: string) {
		const customSubreddits: string[] = this.state.customSubreddits.filter((s: string) => s !== sub);
		this.setState({ customSubreddits });
		localStorage.setItem('customSubs', JSON.stringify(customSubreddits));

	}

	handleSubredditChange = (currentSub: string) => {
		if (this.context.state.currentSub === currentSub) return;
		this.context.updateCurrentSub(currentSub);
		this.context.switchMenuState(false);
	}
}
Subreddit.contextType = RedditTVContext;