import React, { Component } from "react";
import { RedditTVContext } from "../Provider";
import { getVideoInfo } from "../utils/VideoInfo";
import Tools from "../utils/Tools";

import { RouteComponentProps, withRouter } from 'react-router-dom';
interface RouterProps extends RouteComponentProps<any>, React.Props<any> { }

export default class VideoList extends Component<{ history: any, match: any }> {
	allowedDomain: string[] = ['youtube.com', 'youtu.be', 'm.youtube.com', 'vimeo.com', 'streamable.com'];
	currentSub: string;
	isFetching: boolean = false;
	test: boolean = false;
	firstLoaded: boolean = false;
	context: IRedditTVContext;


	componentDidMount() {
		if (this.props.match.params.subreddit) {
			this.context.updateCurrentSub(this.props.match.params.subreddit, this._init);
		} else {
			this._init();
		}
	}

	_init = () => {
		this.currentSub = this.context.state.currentSub;
		this.fetchVideos();
	}

	componentDidUpdate() {
		if (this.currentSub !== this.context.state.currentSub || this.currentSub !== this.props.match.params.subreddit) {
			this.context.updateCurrentSub(this.props.match.params.subreddit, this.fetchVideos)
			this.currentSub = this.props.match.params.subreddit;
			// this.fetchVideos();
		}
	}

	fetchVideos = () => {
		if (this.isFetching) return;
		this.isFetching = true;
		this.context.loading(true);
		this.context.switchMenuState(false);
		fetch(`https://www.reddit.com/r/${this.context.state.currentSub}.json?limit=100`)
			.then(blob => blob.json())
			.then((data) => {
				let items = data.data.children;
				items = items.filter((item: any) => (this.allowedDomain.indexOf(item.data.domain) > -1));
				this.handleFetched(items);
				// this.initPlayer();
			});
	}

	handleFetched = (videos: any) => {
		let items: VideoObj[] = [];
		videos.forEach((_video: any) => {
			_video = _video.data;
			let img = '';
			if (_video.media && _video.preview.images) {
				img = (_video.media && _video.over_18) ? _video.media.oembed.thumbnail_url : _video.preview.images[0].resolutions[0].url;
				img = img.replace(new RegExp('&amp;', 'g'), '&');
			}
			const { id, type } = getVideoInfo(_video.url);
			let video: VideoObj = {
				id,
				type,
				timestamp: Tools.getStartTime(_video.url),
				title: _video.title.replace(/"/g, '&quot;'),
				img,
				over_18: _video.over_18,
				permalink: _video.permalink,
				url: _video.url
			};
			items.push(video);
		});
		let targetVideo: VideoObj[] = [];
		const videoInUrl: boolean = this.props.match.params.video && this.props.match.params.video.length === 11;
		// If there's a video in the URL, check it it's inside the fetched list
		if (videoInUrl) {
			// this.firstLoaded = true;
			targetVideo = items.filter((video: VideoObj) => video.id == this.props.match.params.video);
		}

		// If there's a video in the URL and it's not in the list, prepend it to the list
		if (!targetVideo.length && videoInUrl) {
			items.unshift(this.createVideo(this.props.match.params.video));
		}

		// Add everything to the context
		this.context.updateVideoList(items);

		// If there's a matched video, start there, else, start at 0
		if (targetVideo.length) {
			this.context.setCurrent(targetVideo[0]);
		} else {
			this.context.setCurrent(items[0]);
		}
		this.updateUrl();
		document.querySelector('.VideoList').scrollLeft = 0;
		this.context.loading(false);
		this.isFetching = false;
	}

	render() {
		return (
			<div className="VideoList">
				<ul>
					{this.context.state.videos.map((video: VideoObj, i: number) => (
						<li key={`video-${i}`}>
							<a
								className={`switchVideo video-item ${(video.over_18) ? 'nsfw' : ''} ${(video === this.context.state.currentVideo) ? 'active' : ''}`}
								onClick={(e) => this.setListScrollPos(e, video)} >
								<img
									src={video.img}
									alt={video.title}
									title={video.title} />
							</a>
						</li>
					))}
				</ul>
			</div>
		)
	}

	setListScrollPos = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, video: VideoObj): void => {
		this.context.setCurrent(video, this.updateUrl);
		this.context.switchMenuState(false);
		const target: HTMLAnchorElement = e.currentTarget;
		const videoListHolder: HTMLElement = document.querySelector('.VideoList');

		const scrollTarget = target.getBoundingClientRect().left - (window.innerWidth / 2) + (target.offsetWidth / 2);
		videoListHolder.scrollLeft = videoListHolder.scrollLeft += scrollTarget;
	}

	updateUrl = (): void => {
		this.props.history.push(`/${this.context.state.currentSub ? this.context.state.currentSub : 'videos'}/${this.context.state.currentVideo.id}`);
	}


	createVideo = (id: string): VideoObj => {
		// console.log('aaa');
		// const response = await fetch(`https://www.youtube.com/oembed?url=http://youtu.be/${id}&format=json`, { mode: 'no-cors' }).then((response: any) => response.json());
		// ^^^ This would work, but it's blocked by CORS Policy  (╯°□°）╯︵ ┻━┻
		// console.log('bbb', response);
		return {
			id,
			type: 'youtube',
			permalink: `https://www.youtube.com/watch?v=${id}`,
			url: `https://www.youtube.com/watch?v=${id}`
		}
	}
}
VideoList.contextType = RedditTVContext;

