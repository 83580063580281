import React, { Component, Fragment } from "react";
import Navigation from "./Navigation";
import Dropdown from "./Dropdown";
import { RedditTVContext } from "../Provider";

export default class Header extends Component {
	render() {
		const { currentVideo } = this.context.state;
		return (
			<div className="Header">
				<span className="Header__videoTitle">{currentVideo.title || 'Loading'}</span>
				<Navigation />
				<Dropdown />
			</div>
		)
	}
}
Header.contextType = RedditTVContext;