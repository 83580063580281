interface URLObj {
	scheme: string;
	host: string
	port: string;
	path: string;
	query: string;
	hash: string;
}

export default class Tools {
	static parseUrl(url: string): URLObj {
		const dom: HTMLAnchorElement = document.createElement('a');
		dom.href = url;
		return {
			scheme: dom.protocol.substr(0, dom.protocol.indexOf(':')),
			host: dom.hostname,
			port: dom.port,
			path: dom.pathname,
			query: dom.search.substr(dom.search.indexOf('?') + 1),
			hash: dom.hash.substr(dom.hash.indexOf('#'))
		}
	}

	static parseQuery(qstr: string) {
		let query: any = {};
		let a = (qstr[0] === '?' ? qstr.substr(1) : qstr).split('&');
		for (var i = 0; i < a.length; i++) {
			var b = a[i].split('=');
			query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || '');
		}
		return query;
	}

	static getStartTime(url: string): number {
		let startTime: number = 0;
		const parts: URLObj = Tools.parseUrl(url);
		const $get = Tools.parseQuery(parts.query);
		let time: any = 0;

		if ($get['t'] || $get['amp;t']) {
			if ($get['amp;t']) {
				time = $get['amp;t'];
			} else {
				time = $get['t'];
			}

			if (time.indexOf('m') > -1) {
				time = time.replace('s', '');
				time = time.split('m');
				startTime = (time[0] * 60) + ~~time[1];
			} else {
				startTime = time;
			}
		}

		return ~~startTime;
	}

	static getKeyByValue(object: any, value: string) {
		return Object.keys(object).find(key => object[key] === value);
	}
}