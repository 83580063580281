import React, { createContext, Component } from 'react';

export const RedditTVContext = createContext<IRedditTVContext | null>(null)

export class RedditTVProvider extends Component<{}, RedditTVState> {
	state = {
		currentVideo: {},
		videos: [{}],
		currentSub: 'videos',
		loading: true,
		autoplay: true,
		menuOpened: false,
		canUseShortcuts: true
	}
	render() {
		return (
			<RedditTVContext.Provider value={{
				state: this.state,
				initYTPlayer: (YTPlayer: any) => this.setState({ YTPlayer }),
				toggleAutoplay: () => this.setState({ autoplay: !this.state.autoplay }),
				loading: (x: boolean) => this.setState({ loading: x }),
				canUseShortcuts: (canUseShortcuts: boolean) => this.setState({ canUseShortcuts }),
				updateVideoList: (videos: VideoObj[]) => this.setState({ videos }),
				setCurrent: (currentVideo: VideoObj, cb?: any) => this.setCurrent(currentVideo, cb),
				updateCurrentSub: (currentSub: string, cb: any = null) => this.setState({ currentSub }, cb),
				switchMenuState: (menuOpened: boolean) => this.setState({ menuOpened }),
				setNextVideo: () => {
					const { currentVideo, videos } = this.state;
					const key: number = videos.map((video: VideoObj) => video.id).indexOf((currentVideo as VideoObj).id);
					if (key + 1 >= videos.length) return;
					this.setCurrent(this.state.videos[key + 1]);
				},
				setPreviousVideo: () => {
					const { currentVideo, videos } = this.state;
					const key: number = videos.map((video: VideoObj) => video.id).indexOf((currentVideo as VideoObj).id);
					if (key <= 0) return;
					this.setCurrent(this.state.videos[key - 1]);
				}
			}}>
				{this.props.children}
			</RedditTVContext.Provider>
		)
	}

	setCurrent(currentVideo: VideoObj, cb: any = null) {
		document.title = `${currentVideo.title ? currentVideo.title + ' | ' : ''} Xposed's TV`;
		document.querySelector('meta[property="og:title"]').setAttribute('content', `${currentVideo.title} | Xposed's TV`);
		document.querySelector('meta[property="og:url"]').setAttribute('content', `https://rtv.xposedbones.com/${this.state.currentSub}/${currentVideo.id}`);
		document.querySelector('meta[property="og:image"]').setAttribute('content', currentVideo.img);
		this.setState({ currentVideo }, cb);
	}
}
RedditTVProvider.contextType = RedditTVContext;